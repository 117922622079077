import React from 'react';

function Projects() {
  return (
    <>
        <h1>Under Development</h1>
    </>
  );
}

export default Projects;